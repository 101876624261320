@font-face {
    font-family: "Inter";
    src: url("../assets/fonts/Inter/Inter.ttf");
    font-display: swap;
}

*, ::after, ::before {
    box-sizing: border-box;
    border-width: 0;
    border-style: solid;
    border-color: #e5e7eb;
}

html {
    scroll-behavior: smooth;
    -webkit-text-size-adjust: 100%;
    -moz-tab-size: 4;
    tab-size: 4;
    font-feature-settings: normal;
    font-variation-settings: normal;
}

body {
    margin: 0;
    background-color: $backgroundColor;
    color: $textColor;
    line-height: 1.625;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: 'Inter';
    font-style: normal;
    
    ::selection {
        background-color: $hoverColor;
        color: $selectionTextColor;
    }
}

ul, ol {
    list-style: none;
    margin: 0;
    padding: 0;
}

a {
    color: inherit;
    text-decoration: inherit;
}

img {
    max-width: 100%;
    height: auto;
}

img, svg {
    display: block;
}

h1, h2, h3, p {
    margin: 0;
}

h1, h2, h3 {
    font-size: inherit;
    font-weight: inherit;
}