.Projets {
    margin-bottom: 4rem;
    scroll-margin-top: 4rem;

    @media (min-width: 768px) {
        margin-bottom: 6rem;
    }

    @media (min-width: 1024px) {
        margin-bottom: 9rem;
        scroll-margin-top: 6rem;
    }

    &__Title-container {
        position: sticky;
        top: 0;
        z-index: 20;
        margin: 0 -1.5rem 1rem;
        width: 100vw;
        background-color: $backgroundColorOpacity;
        padding: 1.25rem 1.5rem;
        backdrop-filter: blur(8px);

        @media (min-width: 768px) {
            margin-left: -3rem;
            margin-right: -3rem;
            padding-left: 3rem;
            padding-right: 3rem;
        }

        @media (min-width: 1024px) {
            position: relative;
            top: auto;
            width: 100%;
            height: 1px;
            padding: 0;
            margin: -1px auto;
            overflow: hidden;
            clip: rect(0, 0, 0, 0);
            white-space: nowrap;
            border-width: 0;
            opacity: 0;
        }

        &__title {
            font-size: 0.875rem;
            line-height: 1.25rem;
            font-weight: 700;
            text-transform: uppercase;
            letter-spacing: 0.1em;
            color: $titleactiveColor;

            @media (min-width: 1024px) {
                position: absolute;
                width: 1px;
                height: 1px;
                padding: 0;
                margin: -1px;
                overflow: hidden;
                clip: rect(0, 0, 0, 0);
                white-space: nowrap;
                border-width: 0;
            }
        }
    }

    &__Projet {
        margin-bottom: 3rem;

        &__Container {
            position: relative;
            display: grid;
            padding-bottom: 0.25rem;
            transition-property: all;
            transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
            transition-duration: 150ms;
            gap: 1rem;

            @media (min-width: 640px) {
                grid-template-columns: repeat(8, minmax(0, 1fr));
                gap: 2rem;
            }

            @media (min-width: 768px) {
                gap: 1rem;
            }

            
            @media (min-width: 1024px) {
                &:hover {
                    opacity: 1 !important;
                }
                &:hover &__highlight {
                    background-color: #1e293b80;
                    box-shadow: 0 0 #0000, 0 0 #0000, inset 0 1px 0 0 rgba(148,163,184,0.1);
                    filter: drop-shadow(0 10px 8px rgb(0 0 0 / 0.04)) drop-shadow(0 4px 3px rgb(0 0 0 / 0.1));
                }
                &:hover .Titles__second-name__svg {
                    transform: translate(0.25rem, -0.25rem)
                }
                &:hover .Projets-image {
                    border-color: #e2e8f04d;
                }
            }

            &__highlight {
                position: absolute;
                left: -1rem;
                right: -1rem;
                top: -1rem;
                bottom: -1rem;
                z-index: 0;
                display: none;
                border-radius: 0.375rem;
                transition-property: all;
                transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
                transition-duration: 150ms;

                @media (min-width: 1024px) {
                    left: -1.5rem;
                    right: -1.5rem;
                    display: block;
                }
            }

            &__header {
                z-index: 10;

                @media (min-width: 640px) {
                    grid-column: span 6 / span 6;
                    order: 2;
                }

                .Titles {
                    &__links {
                        display: inline-flex;
                        align-items: baseline;
                        font-weight: 500;
                        font-size: 1rem;
                        line-height: 1.5rem;
                        color: $titleactiveColor;

                        &:hover, &:focus-visible {
                            color: $hoverColor;
                        }

                        &__span {
                            position: absolute;
                            left: -1rem;
                            right: -1rem;
                            top: -0.625rem;
                            bottom: -0.625rem;
                            display: none;
                            border-radius: 0.25rem;

                            @media (min-width: 768px) {
                                left: -1.5rem;
                                right: -1.5rem;
                                top: -1rem;
                                bottom: -1rem;
                            }

                            @media (min-width: 1024px) {
                                display: block;
                            }
                        }
                    }

                    &__second-name {
                        display: inline-block;

                        &__svg {
                            display: inline-block;
                            height: 1rem;
                            width: 1rem;
                            flex-shrink: 0;
                            transition-property: transform;
                            transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
                            transition-duration: 150ms;
                            margin-left: 0.25rem;
                            transform: translate(0, 1px);
                        }
                    }
                }
            }
        }
    }

    &__container:hover .Projets__Projet__Container {
        @media (min-width: 1024px) {
            opacity: 0.5;
        }
    }

    .Projets-description {
        margin-top: 0.5rem;
        font-size: 0.875rem;
        line-height: 1.5;
    }

    .Projets-skills {
        margin-top: 0.5rem;
        display: flex;
        flex-wrap: wrap;

        li {
            margin-top: 0.5rem;
            margin-right: 0.375rem;

            div {
                display: flex;
                align-items: center;
                border-radius: 9999px;
                background-color: #2dd4bf1a;
                padding: 0.25rem 0.75rem;
                font-size: 0.75rem;
                font-weight: 500;
                line-height: 1.25rem;
                color: $hoverColor;
            }
        }
    }

    .Projets-image {
        border-radius: 0.25rem;
        border-width: 2px;
        border-color: #e2e8f01a;
        transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
        transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
        transition-duration: 150ms;

        @media (min-width: 640px) {
            order: 1;
            grid-column: span 2 / span 2;
            transform: translate(0, 0.25rem);
        }
    }
}
