@import "_colors";

@import "index";
@import "Header";
@import "Main";
@import "About";
@import "Experiences";
@import "Projects";
@import "Footer";

.Webpage {
  position: relative;

  &__highlight-background {
    pointer-events: none;
    position: fixed;
    inset: 0px;
    z-index: 30;
    transition-property: all;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 300ms;

    @media (min-width: 1024px) { 
      position: absolute;
    }
  }

  &__content-container {
    margin-left: auto;
    margin-right: auto;
    min-height: 100vh;
    max-width: 1280px;
    padding: 3rem 1.5rem;
    
    @media (min-width: 768px) {
      padding: 5rem 3rem;
    }

    @media (min-width: 1024px) {
      padding: 0 6rem;
    }

    &__content-acces {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      --tw-translate-x: -100%;
      transform: translate(-100%, 0) rotate(0) skewX(0) skewY(0) scaleX(1) scaleY(1);
      border-radius: 0.25rem;
      background-image: linear-gradient(to bottom right, #2dd4bf, #3b82f6, #9333ea);
      padding: 0.75rem 1rem;
      font-size: 0.875rem;
      line-height: 1.25rem;
      font-weight: 700;
      text-transform: uppercase;
      letter-spacing: 0.1em;
      color: white;

      :focus-visible {
        transform: translateX(0px);
      }
    }

    &__content {
      @media (min-width: 1024px) { 
        display: flex;
        justify-content: space-between;
        gap: 1rem;
      }
    }
  }
}