.Footer {
    max-width: 28rem;
    padding-bottom: 4rem;
    font-size: 0.875rem;
    line-height: 1.25rem;
    color: $textColor2;

    @media (min-width: 640px) {
        padding-bottom: 0px;
    }

    &__link {
        font-weight: 500;
        color: $textColor;

        &:hover, &:focus-visible {
            color: $hoverColor;
        }
    }
}